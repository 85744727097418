*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quicksand1';
  color: inherit;
  font-size: inherit;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'AmaticSC';
  src: url('utils/fonts/AmaticSC-Regular.ttf');
}
@font-face {
  font-family: 'AmaticSCBold';
  src: url('utils/fonts/AmaticSC-Bold.ttf');
}
@font-face {
  font-family: 'Jadibeken-Yz4m4';
  src: url('utils/fonts/Jadibeken-Yz4m4.otf');
}
@font-face {
  font-family: 'Cursive1';
  src: url('utils/fonts/Cursive1.otf');
}
@font-face {
  font-family: 'Cursive2';
  src: url('utils/fonts/Cursive2.otf');
}
@font-face {
  font-family: 'Quicksand1';
  src: url('utils/fonts/Quicksand-Regular.ttf');
}
@font-face {
  font-family: 'Quicksand2';
  src: url('utils/fonts/Quicksand-Medium.ttf');
}

:root{
  --main: #ffffff;
  --agave: rgba(135, 157, 153, 0.2);

  --font1: "Quicksand1", 
}


.container{
  width: 100%;
}

.app {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  margin: 0 auto;
  margin: 0 auto;
}
@media (max-width: 600px){
  .app{
    /* flex-direction: column; */
    width: 360px;
    justify-content: center;
  }
}

/* @media (max-width: 1224px) {
  .container{
    max-width: 480px;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px auto;
  }
  .app{
    max-width: 480px;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
  }
} */

/*-----------------------------------------HOME PAGE-------------------------------------------*/

.home-container{
  margin: 0 auto;
  width: 95%;
}


.home-title{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  font-size: 80px;
  font-family: 'AmaticSC';
  text-shadow: 4px 4px 4px rgba(0, 0, 0, .3);
}

hr{
  border: none;
  border-top: 1px solid #464646;
  width: 90%;
  margin: 10px auto;
}


.fade-carousel{
  margin: 0 auto;
  margin-block: 100px;
}

.slider-carousel{
  margin-block: 100px;
}

@media (max-width: 600px){

  .home-container{
    margin: 0 auto;
    width: 98%;
  }

  .home-title{
    font-size: 58px;
  }
  .slider-carousel{
    margin: 0 auto;
    padding-block:20%;
  }
  .fade-carousel{
    margin: 0 auto;
    padding-top:30%;
    padding-bottom: 10%;
  }
}

/*-----------------------------------------NAVBAR-------------------------------------------*/


ul{
  list-style: none;
}

.mobile-menu-button{
  display: none;
}

.header-navigation{
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: fit-content;
  align-items: center;
  width: 75%;
  max-width: 100%;
  margin: 0 auto;
}

.header-logo{
  position: static;
  margin: 0 auto;
}

.site-logo{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 300px;
}

.header-nav-left{
  margin: 0 auto;
}

.header-nav-right{
  margin: 0 auto;
}

.navbar-options{
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.link{
  margin: 0 20px;
  text-decoration: none;
  font-size: 28px;
  font-family: 'AmaticSC';
  font-weight: bold;
  padding-inline: 20px;
}
.link:hover{
  color: rgb(17, 71, 17);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
  transition: color 0.2s ease-in-out, text-shadow 0.1s ease-in-out;
}

.nav-social{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
}

@media (max-width: 600px){
  .header-navigation{
    display: none;
  }

  .link{
    padding-inline: 0;
  }

  .mobile-menu-button{
    display: block;
    text-align: end;
    font-size: 38px;
    padding: 2px;
  }

  .mobile-menu {
    display: block;
    background-color: white;
    position: fixed;
    padding-inline: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: ease-in-out 1.5s;
  }

  .mobile-menu .navbar-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .mobile-menu .link {
    text-decoration: none;
    font-size: 18px;
    color: black;
    line-height: 3;
    font-size: 30px;
    margin-inline: 0;
    padding-inline: 10px;
  }

  .header-logo{
    width: 100%;
    margin: 0 auto;
  }
  .site-logo{
    width: 160px;
  }
}
/*-----------------------------------FOOTER--------------------------------------------------*/

.app-footer{
  margin-top: 10px;
  margin-bottom: 10px;
}

.app-footer nav{
  margin-bottom:10px;
}

.app-footer hr{
  margin-bottom: 10px;
}

.footer-navigation{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 75%;
  max-width: 100%;
  margin: 0 auto;
}

.app-footer .link{
  margin: 0 20px;
  text-decoration: none;
  font-size: 20px;
  font-family: 'AmaticSC';
  font-weight: bold;
}
.app-footer .link:hover{
  color: rgb(17, 71, 17);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
}

.copyright{
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin: 0 auto;
}

@media (max-width: 600px){
  .footer-navigation .navbar-options{
    display: flex;
    flex-direction: row;
  }
}


/*-----------------------------------GENERAL PAGE SETTINGS--------------------------------------------------*/


.page-title{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  text-align: center;
  font-size: 60px;
  font-family: 'AmaticSC';
  text-shadow: 4px 4px 4px rgba(0, 0, 0, .3);
}


/*-----------------------------------BOOK LIST PAGE--------------------------------------------------*/
.book-thumb-container{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 40px auto;
}

.book-thumb-title{
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  font-family: 'Cursive1';
  font-size: 80px;
  font-weight: 500;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 1);
}

.book-thumb-preview{
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 20px 20px;
}

.book-cover-med{
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 2px auto;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 1);
}
  .book-cover-med:hover{
    opacity: .7;
    scale: 105%;
    transition: 0.1s ease-in-out;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 1);
  }

.book-thumb-info{
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 40px;
  padding-left: 40px;
  font-family: var(--font1);
  font-size: 14px;
}

.content-warning{
  font-size: 12px;
  margin-top: 30px;
}   

@media (max-width: 600px){
  .book-thumb-preview{
    flex-direction: column;
    flex-wrap: wrap;
  }
  .book-cover-med{
    width: 40%;
  }
  .book-thumb-info{
    width: 90%;
    padding: 0 0 0 0;
    flex-wrap: wrap;
    text-align: justify;
    margin: 0 auto;
    padding-top: 40px;
  }
}


/*-----------------------------------BOOK PAGE--------------------------------------------------*/
.book-page{
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.book-title{
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  padding-top: 30px;
  font-family: 'Cursive1';
  font-size: 86px;
  font-weight: 500;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 1);
}

.preview-container{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  width: 1200px;
  height: 900px;
  border-radius: 10px;
  box-shadow: inset 0 0 200px rgba(250, 250, 250, 1);
  margin: 40px 0;
  justify-content: center;
  z-index: 1;
}

.container-background{
  position: absolute;
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  height: 92%;
  filter: blur(10px);
  z-index: -1;
}

@media (max-width: 600px){

  .container-background{
    position:absolute;
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    overflow: hidden;
    width: 90%;
    height: 90%;
    transform: scale(2);
    filter: blur(10px);
    z-index: -1;
  }
}

.book-container{
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 20px 20px;
  align-items: center;
}

.book-cover-lrg{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2px 2px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 1);
}

.book-info{
  background-color: rgba(255, 255, 255, 0.1); 
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 75%;
  margin: 2px 12px;
  padding: 0 16px;
  justify-content: center;
  border-radius: 5px;
}
.description{
  font-family: var(--font1);
}

.chapter-preview{
  display:block;
  justify-content: center;
  width: 85%;
  margin: 0 auto;
  font-family: var(--font1);
  /* max-height: 100px; Adjust this value to determine the height at which the text should be truncated */
  overflow: hidden;
  user-select: none;
}

@media (max-width: 600px){
  .preview-container{
    max-height:auto;
    height: 100%;
    position: relative;
  }
  .book-container{
    flex-direction: column;
    flex-wrap: wrap;
  }
  .book-cover-lrg{
    width: 40%;
    margin: 10px auto;
  }  
  .book-info{
    flex-wrap: wrap;
    text-align: justify;
    width: 90%;
    margin: 10px auto;
    padding: 20px 4px;
  }
  .chapter-preview{
    width: 95%;
  }
}
/*-----------------------------------UPCOMING--------------------------------------------------*/

.upcoming-list .book-preview{
  justify-content: center;
  margin: 20px auto;
  width: 50%;
  padding-bottom: 50px;
}

@media (max-width: 600px){
  .upcoming-list .book-title{
    font-size: 60px;
    font-weight: 500;
  }
  .upcoming-list .book-preview{
    justify-content: center;
    margin: 20px auto;
    width: 90%;
    padding-bottom: 50px;
  }


}
/*-----------------------------------ABOUT PAGE--------------------------------------------------*/

.about-container{
  width: 80%;
  margin: 0 auto;
}

.about-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about-text{
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 60%;
  margin: 10px 10px;
  padding-block: 20px;
  line-height: 1.5;
}

.about-social-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px auto;
} 

.about-social-icon{
  padding: 6px;
  margin: 0 15px;
}

.about-social-container .social-icons{
  border-radius: 22px;
  height: 100%;
  width: 40px;
  font-size: 28px;
  background-color: black;
  /* transform: scale(150%); */
}

.about-social-container .social-icons:hover{
  background-color: transparent;
}

@media (max-width: 600px){  

  .about-container{
    width: 100%;
    margin: 0 auto;
  }

  .about-row{
    flex-direction: column;
  }
  .about-text{
    width: 80%;
    line-height: 1.25;
    margin: 0 auto;
  }
  .about-social-container{
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}
/*-----------------------------------SOCIAL MEDIA--------------------------------------------------*/


/* Style all font awesome icons */
.social-icons {
  /* margin-bottom: 20px; */
  /* padding: 20px; */
  font-size: 28px;
  width: 26px;
  text-align: center;
  margin: 0 8px;
}

/* Add a hover effect if you want */
.social-icons:hover {
  opacity: .9;
  color:black;
}

/* Twitter */
.twitter {
  background: #55ACEE;
  color: white;
} 

.instagram{
  background: #fb3958;
  color:white;
}

.envelope{
  background: #35465c;
  color:white;
}

.wattpad{
  background: #ff4500;
  align-items: center;
  text-align: center;
  text-decoration:none; 
}
  .wattpad:hover{
    filter:invert(100%);
  }

  /* For social icons in nav-social */
.nav-social .social-icons{
  background: transparent;
  color:black;
  height: 75%;
  position: relative;
} 
.nav-social .twitter:hover{
  color: #657786;
}
.nav-social .instagram:hover{
  color:#fb3958;
}
.nav-social .envelope:hover{
  color:#00c120;
}
.nav-social .wattpad:hover{
  filter: invert(50%);
  background: transparent;
}



/*-----------------------------------SWIPER CAROUSEL-------------------------------------------------*/

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-pagination{
  opacity: .3;
}
  .swiper-container:hover .swiper-pagination{
    opacity: 1;
  }

.slider-arrow{
  opacity: 0;
  color: rgba(250, 250, 250, .3);
}  
  .swiper-container:hover .slider-arrow{
    opacity: 1;
    color: rgba(250, 250, 250, .3);
    text-shadow: 0 0 2px rgba(0, 0, 0, .5);
    transition: .2s ease-in-out;
  }





